


.base-container-small {
  max-width: 400px;
  width: 100%;
}
.login-form {
  margin-top: 70px;
}
h3.plain {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}


/* Countdown text styles */
.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;
}

/* Countdown text paragraph styles */
.countdown-text > p {
  font-size: 13px;
  font-weight: 400;
  color: #2d1d35;
}

/* Countdown text button styles */
.countdown-text > button {
  background-color: #fff;
  border: none;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

/* Submit button styles */
.submit-btn {
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  background: #FF5C36;
  transition: all ease 0.6s;
}

/* Submit button hover styles */
.submit-btn:hover {
    background-color: #0e6387 !important;
    color: #fff;
 
}