@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
}
body {
  background: #ffffff;
}

#regForm p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 3px;
}
.signature_form .pad {
  padding-left: 25px !important;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#regForm .fieldss {
  margin-bottom: 15px;
}
#regForm {
  background-color: #ffffff;
  margin: 0px auto;
  font-family: "Times New Roman", Times, serif;
  padding: 0px 0px;
  /* border-radius: 10px; */
  border: 3px solid #0097b2;
}
.security-key {
  width: 180px;
  font-size: larger;
  font-weight: 600;
  background-color: #d7f1f0;
  font-style: oblique;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#register {
  background-color: #0097b2;
  color: #ffffff;
  padding: 20px 30px;
  /* border-radius: 10px 10px 20px 20px  ; */
}
#register1 {
  background-color: #0097b2;
  color: #ffffff;
  padding: 20px 30px;
  /* border-radius: 10px 10px 20px 20px  ; */
}
.content-tab {
  padding: 0px 30px;
}
.content-tab1 {
  padding: 0px 20px;
  margin-top: 20px;
}
.all-steps {
  padding: 0px 40px;
}
h4 {
  text-align: center;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.card-title {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #ff5c36;
  line-height: 48px;
}
.step-list li {
  font-weight: 500;
}
.custom-btn {
  padding: 5px 70px;

  color: #fff;
  background-color: #27ae60;
}
.beneficial-owner-btn {
  background: "#467ac7";
  font-weight: 600;
  font-size: 14px;
  color: #467ac7;
  background-color: transparent;
  margin-top: 13px;
  border: 1px solid #467ac7;
}
.beneficial-owner-btn:hover {
  background-color: #467ac7;

  color: #fff;
}

.custom-btn:hover {
  background-color: #27ae60;
  border-color: #27ae60;
  color: #fff;
}
.card-body {
  padding: 25px;
  background-color: #e8eeef;
}
input {
  padding: 7px;
  width: 100%;
  font-size: 17px;
  margin-top: 5px;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-weight: 500;
  color: black;
  /* -webkit-appearance: none; */
}

.form-check-input:checked {
  background-color: #1bb9f3 !important;
  /* border: none !important; */
  box-shadow: none !important;
}

.checkBoxStepOne {
  margin-bottom: 18px;
}

.tab input:focus {
  border: 1px solid #0097b2 !important;
  outline: none;
}

input.invalid {
  border: 1px solid #e03a0666;
}
.fileTitle {
  font-size: 14 !important;
  font-style: "italic" !important;
  font-weight: 600 !important;
  font-family: "fangsong" !important;
  color: "#4b4343" !important;
}
.fileLabel {
  font-size: 14px;
  font-weight: 900;
  font-style: italic;
}
.font-weight-500 {
  font-weight: 600;
}
.tab.content-tab p {
  /* Apply font family and style to all <p> elements within .tab.beneficial-tab */
  font-family: Arial, sans-serif;
  font-weight: 500;
  color: black;
}
.tab h5 {
  font-size: 18px;
  /* Apply font family and style to all <p> elements within .tab.beneficial-tab */
  font-family: Arial, sans-serif;
  font-weight: 500;
  color: black;
}
/* usama works */
/* .signature_form{
  background-color: #ffffff;
  margin: 0px auto;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 10px;
  border : 2px solid #0097B2
} */
.signature_list_item {
  hyphens: auto;
  margin-bottom: 10px;
}
.marf li {
  margin-bottom: 10px;
}
.signature_list_item::marker {
  color: #ff5c36;
}
.inlineTexts {
  font-weight: 500;
  color: #ff5c36;
}

.singature_tabs {
  position: relative;
  cursor: pointer;
  color: #4373df;
}

.singature_tabs::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to set the height of the underline */
  width: 100%;
  border-bottom: 4px solid #4373df;
  cursor: pointer; /* Adjust this value to set the height and color of the underline */
}

/* .tab {
    display: none
} */

button {
  background-color: #ff5c36;
  color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ff5c36;
  /* border-radius: 50%; */
  padding: 6px 18px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

button:focus {
  outline: none !important;
}

#prevBtn {
  margin-right: 8px;
}

.all-steps {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  /* display: inline-flex;
    justify-content: center; */
}

.step {
  height: 40px;
  width: 40px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #6a1b9a;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  color: #fff;
  background: #6a1b9a;
  opacity: 1;
}

.thanks-message {
  display: none;
}

/* body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
  }
   */
.fileContainer {
  border: 4px dashed rgb(153 195 227);
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
}
.fileContainer1 {
  display: flex;
  flex-direction: row;

  width: 300px;
}

.viewButton {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
}

.viewButton:hover {
  background-color: #45a049;
}

.fileInput {
  display: none; /* Hide the file input */
}

.btnFile {
  margin-left: 64px;
  background: none;
  border: none;
  font-size: 16px;

  font-weight: 600;
  text-decoration: underline;
  color: #467ac7;
}
/* label {
    cursor: pointer;
  }
  
  label h2 {
    color: rgb(54, 151, 224);
  } */

.upload__icon {
  margin-bottom: 3px;
  color: rgb(54, 151, 224);
}
#fileId {
  display: none;
}

/* label p {
    font-size: 14px;
    color: rgb(10, 52, 85);
  }
  
  fileUpload {
    display: none;button
  } */

.fileDiv {
  flex-direction: row;
}
.socialDiv {
  padding-left: 58px;
}

.custom-popup {
  font-size: 10px; /* Adjust as needed */
}

.confirmation-check {
  margin: 10px 0px 18px 0px;
  align-items: center !important;
  background: #efd5ff;
  padding: 10px;
  border-radius: 10px;
}

.modal-header {
  background-color: #0097b2;
  color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.modal p {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.bkgrnd {
  background-color: #e9f6f8;
  padding: 30px 15px;
}
.bkgrnd ul {
  padding-left: 20px;
}
.modal ul li {
  list-style: none;
  font-size: 18px;
}
.modal p b {
  font-size: 22px;
  font-weight: 700;
}
.dot_img {
  margin-top: 3px;
}
.modal .spann {
  color: #ff5c36;
  font-weight: 700;
}
.btn-close:focus {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .signature_list_item {
    hyphens: none;
    text-align: left !important;
  }
  .signature_form p {
    hyphens: auto;
  }
}
@media screen and (min-width: 200px) and (max-width: 1300px) {
  .fileDiv {
    flex-direction: column !important;
  }
  .socialDiv {
    padding-left: 0px !important;
  }
  .fileContainer {
    width: 250px !important;
  }
  .uploadFileDiv {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1518px) {
  #id_date_of_formation {
    width: 300px !important;
    display: block !important; /* Display input on a new line */
  }
}
